import React, { Component } from 'react'
import { signAndSendTx } from '../services/trezor'
import {
  cancelSendTx,
  fetchAllUxto,
  fetchCollectorUxto,
  fetchLTCScanStatus,
  fetchUnsignedTx,
  setWithdrawNonce,
  signTx,
} from '../services/api'
import { getChainByCoinType } from '../services/token_manager'
import * as walletService from '../services/wallet'
import { format } from '../utils'

const SEND_TYPE_COMMISSION = 'COMMISSION'
const SEND_TYPE_COLLECT = 'COLLECT_DEPOSIT'
const SEND_TYPE_WITHRAW = 'WITHDRAW'

const TX_CHAIN_HISTORY = 'tx.chain.history'
const TX_DB_HISTORY = 'tx.db.history'

const Withdraw = (props) => {
  return [
    <tr key={'withdraw'}>
      <th>id(withdraw)</th>
      <th />
      <th />
      <th>value</th>
      <th>coinType</th>
      <th>chainStatus</th>
      <th>dbStatus</th>
      <th>to</th>
    </tr>,
    props.data.map(WithdrawRow),
  ]
}
const WithdrawRow = (item, index) => {
  const { to, value, id, coinType, onTxBroadcasted, sendType, onDelTxSend, chainStatus, dbStatus } = item
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>
        <button
          disabled={coinType === 'LTC' || coinType === 'DOGE'}
          onClick={() => signAndSendTx(item, onTxBroadcasted)}
          className={'btn btn-sm'}
        >
          sign
        </button>
      </td>
      <td>
        <button
          disabled={sendType === SEND_TYPE_WITHRAW}
          onClick={() => cancelSendTx(id, onDelTxSend)}
          className={'btn btn-sm'}
        >
          cancel
        </button>
      </td>
      <td>{format(value)}</td>
      <td>{coinType}</td>
      <th>{chainStatus}</th>
      <th>{dbStatus}</th>
      <td>{to}</td>
    </tr>
  )
}
const Deposit = (props) => {
  return [
    <tr key={'deposit'}>
      <th>id(deposit)</th>
      <th />
      <th />
      <th>value</th>
      <th>coinType</th>
      <th>chainStatus</th>
      <th>dbStatus</th>
      <th>collectAddr</th>
      <th>collectPath</th>
      <th>to</th>
    </tr>,
    props.data.map(DepositRow),
  ]
}
const DepositRow = (item, index) => {
  const { id, value, collectAddr, collectPath, to, coinType, onTxBroadcasted, onDelTxSend, chainStatus, dbStatus } =
    item
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>
        <button onClick={() => signAndSendTx(item, onTxBroadcasted)} className={'btn btn-sm'}>
          sign
        </button>
      </td>
      <td>
        <button onClick={() => cancelSendTx(id, onDelTxSend)} className={'btn btn-sm'}>
          cancel
        </button>
      </td>
      <td>{format(value)}</td>
      <td>{coinType}</td>
      <th>{chainStatus}</th>
      <th>{dbStatus}</th>
      <td>{collectAddr}</td>
      <td>{collectPath}</td>
      <td>{to}</td>
    </tr>
  )
}
const Commission = (props) => {
  return [
    <tr key={'commission'}>
      <th>id(commission)</th>
      <th />
      <th />
      <th>value</th>
      <th>coinType</th>
      <th>chainStatus</th>
      <th>dbStatus</th>
      <th>to</th>
    </tr>,
    props.data.map(WithdrawRow),
  ]
}

class TxSendList extends Component {
  timer = 1

  constructor(props) {
    super(props)
    this.state = { txArr: [] }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.timer = 0
  }

  componentDidMount() {
    this.refreshTx(false)
  }

  refreshTx = async (loop = false) => {
    try {
      const txArr = await fetchUnsignedTx()
      this.setState({ txArr })
    } catch (error) {
      console.error('txsend list task with error', error)
    }

    if (loop && this.timer) {
      this.timer = setTimeout(() => this.refreshTx(true), 5 * 1000)
    }
  }

  updateChainStatus = (_id) => {
    const { txArr } = this.state
    const index = txArr.findIndex(({ id }) => id === _id)
    console.log('updateChainStatus id is ', _id, txArr)
    const item = txArr[index] || {}
    console.log('%d chain status changed', item.id, item)
    const txChainHistory = JSON.parse(localStorage.getItem(TX_CHAIN_HISTORY)) || {}
    txChainHistory[item.id] = true
    localStorage.setItem(TX_CHAIN_HISTORY, JSON.stringify(txChainHistory))
    this.forceUpdate()
  }
  updateDbStatus = (_id) => {
    const { txArr } = this.state
    const index = txArr.findIndex(({ id }) => id === _id)
    console.log('updateDbStatus id is ', _id, txArr)
    const item = txArr[index] || {}
    console.log('%d db status changed', item.id, item)
    const txDbHistory = JSON.parse(localStorage.getItem(TX_DB_HISTORY)) || {}
    txDbHistory[item.id] = true
    localStorage.setItem(TX_DB_HISTORY, JSON.stringify(txDbHistory))
    this.forceUpdate()
  }

  onTxBroadcasted = async (err, result, id, coinType, collectPath) => {
    // console.log('tx broadcasted:', id, err, result)
    if (!err) {
      this.updateChainStatus(id)
      const { txid, nonce, fee } = result
      const r = await signTx({ txHash: txid, nonce, id, fee })
      console.log('save tx:', r)
      this.refreshTx()
      this.updateDbStatus(id)
      this.props.refreshSigned()
      // 管理同时审批nonce冲突
      if (!collectPath && nonce && coinType !== 'FIL') {
        let key
        key = `trezor.${getChainByCoinType(coinType)}`
        const res = await setWithdrawNonce(nonce, key)
        console.log('updateDBnonce %s nonce ', res, nonce)
      }
      console.info('----------- success ----------------')
      alert('成功')
    } else {
      console.error(err)
      alert(`签名失败: ${err}`)
    }
  }
  onDelTxSend = () => {
    this.refreshTx()
  }

  _map2NewTxArr = ({
    gas,
    gasPrice,
    sendToAddress,
    amount,
    id,
    collectPath,
    collectAddr,
    sendType,
    coinType,
    fee,
    chainStatus,
    dbStatus,
  }) => ({
    gasLimit: gas,
    to: sendToAddress,
    value: amount,
    collectPath,
    collectAddr,
    id,
    gasPrice,
    sendType,
    coinType,
    fee,
    chainStatus,
    dbStatus,
    onDelTxSend: this.onDelTxSend,
    onTxBroadcasted: (err, result) => this.onTxBroadcasted(err, result, id, coinType, collectPath),
  })

  confirmBatchSize(size = 10) {
    return prompt('确认uxto单次处理数量？', `${size}`)
  }

  async collectLTC() {
    const size = this.confirmBatchSize()
    const abort = window.confirm('LTC节点只能运行一个任务，如果存在任务是否打断并执行本任务？')
    if (!size) return
    try {
      const coin = 'LTC'
      setTimeout(() => this.checkLTCScan(), 1000)
      const { uxtos, collector, fee } = await fetchAllUxto(coin, abort)
      await walletService.collectBtcFamily(coin, uxtos, collector, fee, size)
      alert(`收集完成`)
    } catch (e) {
      alert(`收集失败: ${e}`)
    }
  }

  checking_ltc_scan = false

  async checkLTCScan() {
    if (this.checking_ltc_scan) return
    this.checking_ltc_scan = true
    const { progress } = await fetchLTCScanStatus()
    if (!progress) {
      console.log('LTC UTXO 当前没有扫描任务')
      this.checking_ltc_scan = false
      return
    }
    while (true) {
      const { progress } = await fetchLTCScanStatus()
      if (!progress) break
      console.log(`LTC UTXO 当前扫描进度${progress}%`)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    this.checking_ltc_scan = false
  }

  signLTCs = async (txs) => {
    try {
      const coin = 'LTC'
      const { uxtos, collector, fee } = await fetchCollectorUxto(coin)
      const txid = await walletService.withdrawBtcFamily(coin, uxtos, txs, collector, fee)

      for (const tx of txs) {
        const { id } = tx
        this.updateChainStatus(id)
        const r = await signTx({ txHash: txid, nonce: 0, id, fee })
        console.log('save tx:', r)
        this.refreshTx()
        this.updateDbStatus(id)
        this.props.refreshSigned()
      }

      alert('成功')
    } catch (error) {
      console.error('signLTCs err', error)
    }
  }

  async collectDOGE() {
    const size = this.confirmBatchSize()
    if (!size) return
    try {
      const coin = 'DOGE'
      const { uxtos, collector, fee } = await fetchAllUxto(coin)
      await walletService.collectBtcFamily(coin, uxtos, collector, fee, size)
      alert(`收集完成`)
    } catch (e) {
      alert(`收集失败: ${e}`)
    }
  }

  signDOGEs = async (txs) => {
    try {
      const coin = 'DOGE'
      const { uxtos, collector, fee } = await fetchCollectorUxto(coin)
      const txid = await walletService.withdrawBtcFamily(coin, uxtos, txs, collector, fee)

      for (const tx of txs) {
        const { id } = tx
        this.updateChainStatus(id)
        const r = await signTx({ txHash: txid, nonce: 0, id, fee })
        console.log('save tx:', r)
        this.refreshTx()
        this.updateDbStatus(id)
        this.props.refreshSigned()
      }

      alert('成功')
    } catch (error) {
      console.error('signDOGEs err', error)
    }
  }

  render() {
    const { txArr } = this.state

    const txChainHistory = JSON.parse(localStorage.getItem(TX_CHAIN_HISTORY)) || []
    const txDbHistory = JSON.parse(localStorage.getItem(TX_DB_HISTORY)) || []
    const newTxArr = []
    for (const item of txArr) {
      const chainStatus = txChainHistory[item.id] ? 'O' : 'x'
      const dbStatus = txDbHistory[item.id] ? 'O' : 'x'
      newTxArr.push({ ...this._map2NewTxArr(item), chainStatus, dbStatus })
    }
    const depositArr = newTxArr.filter(({ sendType }) => sendType === SEND_TYPE_COLLECT)
    const withdrawArr = newTxArr.filter(({ sendType }) => sendType === SEND_TYPE_WITHRAW)
    const commissionArr = newTxArr.filter(({ sendType }) => sendType === SEND_TYPE_COMMISSION)
    return (
      <div>
        <div className={'flex space-x-2'}>
          <h2 className={'text-lg'}>UnSigned Tx</h2>
          <button onClick={() => this.refreshTx()} className={'btn btn-sm ml-4'}>
            Refresh
          </button>
          <button onClick={() => this.collectLTC()} className={'btn btn-sm'}>
            收集LTC
          </button>
          <button onClick={() => this.checkLTCScan()} className={'btn btn-sm'}>
            查看LTC UTXO扫描进度
          </button>
          <button
            onClick={() => this.signLTCs(newTxArr.filter((item) => item.coinType === 'LTC'))}
            className={'btn btn-sm'}
          >
            Sign LTC TXs
          </button>
          <button onClick={() => this.collectDOGE()} className={'btn btn-sm'}>
            收集DOGE
          </button>
          <button
            onClick={() => this.signDOGEs(newTxArr.filter((item) => item.coinType === 'DOGE'))}
            className={'btn btn-sm'}
          >
            Sign DOGE TXs
          </button>
        </div>
        <table className={'table table-zebra table-compact w-full'}>
          <tbody>
            <Deposit data={depositArr} />
            <Withdraw data={withdrawArr} />
            <Commission data={commissionArr} />
          </tbody>
        </table>
      </div>
    )
  }
}

export default TxSendList
