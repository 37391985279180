export const APP_ENV = process.env.REACT_APP_ENV
export const REACT_APP_BUILD_TIME = process.env.REACT_APP_BUILD_TIME
  ? new Date(parseInt(process.env.REACT_APP_BUILD_TIME)).toLocaleString()
  : 'unknow'

export const SPRING_URL = process.env.REACT_APP_SPRING_URL || 'http://localhost:8081'
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000'

export const ETH_MAINNET_RPC_URL =
  process.env.REACT_APP_ETH_MAINNET_RPC_URL || 'https://mainnet.infura.io/v3/f33054dd6a414039827b7b3b036ebbda'
export const ETC_MAINNET_RPC_URL = process.env.REACT_APP_ETC_MAINNET_RPC_URL || 'https://etc.mytokenpocket.vip'
export const INSIGHT_URL = 'https://insight.bitpay.com/api/'

// localstorage keys
export const API_TOKEN_KEY = 'api.token'
export const ERC20_TOKENS_KEY = 'erc20.tokens.config'

/**
 * XPUB PATH 查询 https://github.com/satoshilabs/slips/blob/master/slip-0044.md
 */
export const BTC_NETWORK = process.env.REACT_APP_BTC_NETWORK

// ETH
export const ETH_NETWORK = process.env.REACT_APP_ETH_NETWORK || 'ropsten'
export const ETH_MAIN_WALLET_KEY = 'eth.wallet.addr.sending'
export const ETH_MAIN_WALLET_FULL_PATH = `m/44'/60'/0'/0/0`
export const ETH_XPUB_KEY = 'eth.wallet.xpub'
export const ETH_USER_WALLET_BASE_PATH = `m/44'/60'/1'`

// TRX
export const TRX_MAIN_WALLET_KEY = 'trx.wallet.addr.sending'
export const TRX_MAIN_WALLET_FULL_PATH = `m/44'/195'/0'/0/0`
export const TRX_XPUB_KEY = 'trx.wallet.xpub'
export const TRX_USER_WALLET_BASE_PATH = `m/44'/195'/1'`

// USDT & BTC
export const USDT_MAIN_WALLET_KEY = 'usdt.wallet.addr.sending'
export const BTC_MAIN_WALLET_FULL_PATH = BTC_NETWORK === 'testnet' ? `m/44'/1'/0'/0/0` : `m/44'/0'/0'/0/0`
export const USDT_XPUB_KEY = 'usdt.wallet.xpub'
export const USDT_USER_WALLET_BASE_PATH = `m/44'/7189'/0'`

export const USDT_MIN_AMOUNT = 0.00000547

// LTC
export const LTC_XPUB_KEY = 'ltc.wallet.xpub'
export const LTC_USER_WALLET_BASE_PATH = `m/44'/2'/0'`

// DOGE
export const DOGE_MAIN_WALLET_KEY = 'doge.wallet.addr.change'
export const DOGE_MAIN_WALLET_FULL_PATH = `m/44'/3'/0'/1/0`
export const DOGE_XPUB_KEY = 'doge.wallet.xpub'
export const DOGE_USER_WALLET_BASE_PATH = `m/44'/3'/0'`

// FIL
export const FIL_MAIN_WALLET_FULL_PATH = `m/44'/461'/0'/0/0`
export const FIL_XPUB_KEY = 'filecoin.wallet.xpub'
export const FIL_USER_WALLET_BASE_PATH = `m/44'/461'/0'`

// ZEC
export const ZEC_MAIN_WALLET_KEY = 'zec.wallet.addr.sending'
export const ZEC_MAIN_WALLET_FULL_PATH = `m/44'/133'/0'/0/0`
export const ZEC_XPUB_KEY = 'zec.wallet.xpub'
export const ZEC_USER_WALLET_BASE_PATH = `m/44'/133'/0'`
